<template>
  <v-main align="center" style="height: 100%">
    <db-map is-form />
  </v-main>
</template>

<script>
import DbMap from "./DbMap.vue";

export default {
  name: "DbMapCreateDashboard",

  components: { DbMap },
};
</script>